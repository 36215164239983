<template>
  <v-col class="col-11">
    <v-row
      :class="`${currentClass} pr-3`"
    >
      <v-col
        class="text-truncate"
        :title="printValue"
      >
        {{ printValue }}
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'TableColumn',
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    headerClass() {
      return this.header.class
    },
    headerKey() {
      return this.header.key
    },
    currentClass() {
      const { headerClass } = this
      return (typeof headerClass === 'function' && headerClass(this.item.normalized[this.headerKey])) || headerClass
    },
    currentValue() {
      return this.item[this.headerKey]
    },
    isArray() {
      return this.currentValue instanceof Array
    },
    printValue() {
      const { currentValue } = this
      const printString = () => typeof currentValue === 'string' && currentValue
      const printArray = () => Array.isArray(currentValue) && this.getArrayValue(currentValue)
      const printObject = () => currentValue && typeof currentValue === 'object' && this.getObjectValue(currentValue)

      return printString() || printArray() || printObject() || String(currentValue)
    },
  },
  methods: {
    getArrayValue(currentValue) {
      return currentValue.map((value) => {
        const isStringArray = () => typeof value === 'string' && value
        // @TODO Review perf on deep getter: `value.join(', ')` vs `value.map((val) => this.getArrayValue(val)).join(', ')`
        const isArrayArray = () => Array.isArray(value) && ((value.length && value.join(', ')) || 'None')
        const isObjectArray = () => value && typeof value === 'object' && this.getObjectValue(value)
        return isStringArray() || isArrayArray() || isObjectArray() || 'None'
      }).join(', ')
    },
    getObjectValue(value) {
      const keys = Object.keys(value)
      const keyLength = keys.length
      return (keyLength && keys.reduce((strValue, valueKey, valueIndex) => {
        const delimiter = (valueIndex < (keyLength - 1) && ', ') || ''
        strValue += `${valueKey} = ${value[valueKey]}${delimiter}`
        return strValue
      }, '')) || 'None'
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/tables";

</style>
